import React, { FC } from "react";

interface Props {
  query: string;
  setQuery: any;
  label?: any;
  type?: any;
}

export const FilterQuery: FC<Props> = (props) => {
  const { query, setQuery, label, type } = props;
  return (
    <div className="mt-4">
      <div className="ml-sm-2 d-flex align-items-center mt-4 mt-sm-0 mr-4">
        <div className="form-group mb-0 w-100">
          <input
            type={type ? type : "text"}
            className="form-control"
            name={new Date() + "search"}
            placeholder="Search"
            value={query}
            onChange={(e) => {
              e.preventDefault();
              setQuery(e.currentTarget.value);
            }}
          />
          <small className="form-text text-muted">
            <b>Search</b> {label ? label : "in all fields"}
          </small>
        </div>
      </div>
    </div>
  );
};
