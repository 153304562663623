import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../../helpers";
import { Card, CardBody, CardHeader } from "../../../../partials/controls";
import { getAdmins, deleteAdmin } from "../../../../service/adminServices";
import { Link } from "react-router-dom";
import { SizePerPage } from "../../../../common/table/filters/SizePerPage";
import { Table } from "../../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../../../common/loader";
import { FilterQuery } from "../../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../../common/table/filters/StatusFilter";
import { useHistory } from "react-router";
import { sortServerString } from "../../../../common/table/sorter";
import { PaginationLinks } from "../../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../../common/pagination/PaginationToolbar";
import { Modal } from "react-bootstrap";
import moment from "moment";

const Users: FC = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("active");
  const [sortingType, setSortingType] = useState<any>("");

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${
            status === "active" ? `success` : `danger`
          } label-inline font-weight-normal font-size-11`}
        >
          {status === "active" ? "Active" : "Inactive"}
        </span>
      );
    }
  };
  const openEditUser = (id) => {
    history.push(`/account/admin/update/${id}`);
  };
  const ActionEdit = (cellContent: any, row: any, rowIndex: any) => {
    return (
      <>
        <div
          title="Edit Admin"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditUser(row._id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </div>

        {row?.status === "active" ? (
          <div
            title="Delete Admin"
            className="btn btn-icon btn-light btn-sm mx-3"
            onClick={() => {
              setSelectedId(row?._id);
              setShow(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/trash.svg")}
              />
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const columns = [
    {
      dataField: "username",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: ActionEdit,
    },
  ];

  const getUsersList = (page = 1, sizePerPage = 10, showLoading = true) => {
    const isStatus = type !== "" ? `&filter={"status":"${type}"}` : "";

    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sortOrder=asc&field=username`;

    if (showLoading) {
      setLoading(true);
    }
    getAdmins(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);

        if (res?.data?.docs.length === 0 && page !== 1) {
          getUsersList(page - 1);
          setPage(page - 1);
          return;
        }

        const listData = res?.data?.docs.map((data: any, index: any) => {
          return {
            _id: data._id,
            index: ++index + (page - 1) * sizePerPage,
            username: data.username,
            phoneNumber: data.phoneNumber,
            email: data?.email,
            status: data?.status,
            createdAt: moment(data?.createdAt).format("YYYY-MM-DD"),
          };
        });

        setEntities(listData);
        setTotalData(res?.data?.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        history.push("/auth/login");
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getUsersList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getUsersList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
  };

  useEffect(() => {
    getUsersList(1, 10, false);

    // eslint-disable-next-line
  }, [query, type, sortingType]);

  const onSwitchAction = (id: any, status: any) => {
    deleteAdmin(id)
      .then((res: any) => {
        if (res?.status === 200) {
          getUsersList(page);
          setShow(false);
          setSelectedId(null);
          setPage(page);
          setSizePerPage(10);
        }
      })
      .catch((err) => {});
  };

  return (
    <Card>
      <Modal
        show={show}
        onHide={() => {
          setSelectedId(null);
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Body>Please confirm delete this Admin.</Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setShow(false);
                setSelectedId(null);
              }}
              className="btn btn-secondary btn-elevate"
            >
              Close
            </button>
            <> </>
            <button
              type="button"
              className="btn btn-primary btn-elevate"
              onClick={() => {
                onSwitchAction(selectedId, "Inactive");
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {loading && <Loader />}
      <CardHeader title="Admin">
        <div className="d-flex align-items-center">
          <div
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getUsersList();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-md-row flex-sm-column">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <FilterQuery
                    query={query}
                    label="Username"
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                  <StatusFilter
                    status={type}
                    setStatus={(e: any) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "disactivated" },
                    ]}
                  />
                </div>

                <div className="mt-4">
                  <Link className="btn btn-primary" to="/account/admin/add">
                    New Admin
                  </Link>
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default Users;
