import React, { useEffect, useState } from "react";
import qs from "querystring";
import { doUnsubscribeEDM } from "../service/unsubscribe";

const UnsubscribeEDM = (props) => {
  const [status, setStatus] = useState("initial");
  const [errMsg, setErrMsg] = useState("");

  const params: any = qs.parse(props.location.search);
  const origialGatewayID: any = params["?ll"];
  const originalEmail: any = params["i"];

  useEffect(() => {
    const payload = {
      gatewayId: origialGatewayID,
      receiver: window.atob(originalEmail),
    };
    doUnsubscribeEDM(payload)
      .then((res) => {
        setErrMsg("");
        setStatus("success");
      })
      .catch((err) => {
        setErrMsg(err?.response?.data?.message);
        setStatus("fail");
      });

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ maxWidth: "1200px" }} className="p-8 m-auto">
      <h1 className="text-center font-weight-bold">
        {status === "initial"
          ? "Unsubscribing..."
          : status === "success"
            ? "Unsubscribed Successfully!"
            : errMsg !== ""
              ? errMsg
              : "Something went wrong. Please try again after some time."}
      </h1>

      {status === "initial" ? null : (
        <div className="text-center m-10">
          <img
            src={
              status === "success"
                ? "/media/svg/icons/success.svg"
                : "/media/svg/icons/error.svg"
            }
            alt="status_icon"
            width={100}
            height={100}
          />
        </div>
      )}
    </div>
  );
};

export default UnsubscribeEDM;
