import api from "../../common/api";

export const getAllEDMHeaderFooter = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`edm-header-footer/list/all`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getEDMHeaderFooter = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `edm-header-footer?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneEDMHeaderFooter = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`edm-header-footer/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewEDMHeaderFooter = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`edm-header-footer/create`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateEDMHeaderFooter = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`edm-header-footer/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
