import api from "../common/api";

export const getAllGateways = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`gateway/list/all`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getGroupMessages = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any,
  schedDate: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `groups/message?page=${page}&limit=${limit}${q}${type}${sort}${schedDate}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getMessages = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any,
  schedDate: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `message?page=${page}&limit=${limit}${q}${type}${sort}${schedDate}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getMessagesByGroupID = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any,
  schedDate: any,
  id: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `message/group/${id}?page=${page}&limit=${limit}${q}${type}${sort}${schedDate}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneMessage = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`message/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getGroupFirstMessage = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`message/grp/first/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateMultipleStatus = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`message/update/multiple`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewMessage = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`message/create`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateMessage = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`message/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateGroupMessage = (id: any, msgId: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`message/group/${id}/${msgId}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteMessage = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`message/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteMessageWithGroup = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`message/group/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteMultipleMesages = (ids: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`message/delete/multiple/`, { ids: ids })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getMessageLog = (
  page: number,
  limit: number,
  type: any,
  sort: any,
  id: any
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `log/message/${id}?page=${page}&limit=${limit}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
