import React, { FC } from "react";
import { Select } from "../../components/Select";
interface Select {
  value: string;
  label: string;
}

interface Props {
  status: string;
  setStatus: any;
  options: Select[];
  label?: any;
  noLabel?: any;
  noMargin?: boolean;
}

export const StatusFilter: FC<Props> = (props) => {
  const {
    status,
    setStatus,
    options,
    label = "status",
    noLabel = false,
    noMargin = false,
  } = props;
  return (
    <div className={noMargin ? "" : "mt-4 mr-3"}>
      <div className=" d-flex flex-row">
        <Select
          value={status}
          setValue={setStatus}
          options={options}
          name={label || "status"}
          className="top-select"
          noLabel={noLabel}
        />
      </div>
    </div>
  );
};
