import api from "../common/api";

export const getAdmins = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`admins?page=${page}&limit=${limit}${q}${type}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneAdmin = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`users/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewAdmin = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`admins/create-admin`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateAdmin = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`admins/edit-admin/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteAdmin = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`users/delete-user/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
