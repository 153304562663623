import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import CreatableSelect from "react-select/creatable";
import { useFormik } from "formik";
import { InboxOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { Upload } from "antd";
import {
  getOneGatway,
  addNewGateway,
  updateGatway,
} from "../../../service/gatewayServices";
import { getAllCompanies } from "../../../service/companyService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
interface Values {
  gatewayName: string;
  companyId: any;
  type: string;
  connectionURL: string;
  loginName: string;
  password: string;
  port: string;
  otherKey: string;
  enabled: boolean;
  recieverInfoURL: string;
  authKey: string;
  authValue: string;
  template_name: string;
  lang_code: string;
}

interface Params {
  id?: string;
}

const { Dragger } = Upload;

const AddGateway = () => {
  const history = useHistory();
  const [gateway, setGateway] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<any>([]);

  const getAllCompaniesData = () => {
    getAllCompanies()
      .then((res: any) => {
        const newOptions = res?.data?.data?.map((x) => {
          return {
            label: x?.name,
            value: x?.name,
          };
        });
        setCompanyList(newOptions || []);
      })
      .catch((err) => {});
  };

  const getOneGatewayDetails = () => {
    setLoader(true);
    getOneGatway(params?.id)
      .then((res: any) => {
        setLoader(false);
        setGateway(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "Active"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const props: UploadProps = {
    name: "file",
    action: "dummy",
    accept: "application/json",
    maxCount: 1,
    onRemove: (file) => {
      const index = selectedFile.indexOf(file);
      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);
    },
    beforeUpload: (file, filess) => {
      // setSelectedFile([file]);
      return false;
    },
    onChange: (file) => {
      if (file.fileList) {
        setSelectedFile(file.fileList);
      }
    },
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("gatewayName", value.gatewayName);
    formData.append("companyId", value.companyId?.value);
    formData.append("type", value.type);
    formData.append("connectionURL", value.connectionURL);
    formData.append("loginName", value.loginName);
    formData.append("password", value.password);
    formData.append("port", value.port);
    formData.append("otherKey", value.otherKey);
    formData.append("recieverInfoURL", value.recieverInfoURL);
    formData.append("authKey", value.authKey);
    formData.append("authValue", value.authValue);
    formData.append("status", isSwitchOn === true ? "Active" : "Inactive");
    if (selectedFile.length) {
      formData.append("file", selectedFile[0]?.originFileObj);
    }
    if (value.type === "WHATSAPP") {
      formData.append("template_name", value.template_name);
      formData.append("lang_code", value.lang_code);
    }
    updateGatway(params.id, formData)
      .then((res: any) => {
        setLoader(false);

        toast.success("Gateway update Successful");
        history.push("/gateway");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("gatewayName", value.gatewayName);
    formData.append("companyId", value.companyId?.value);
    formData.append("type", value.type);
    formData.append("connectionURL", value.connectionURL);
    formData.append("loginName", value.loginName);
    formData.append("password", value.password);
    formData.append("port", value.port);
    formData.append("otherKey", value.otherKey);
    formData.append("recieverInfoURL", value.recieverInfoURL || "");
    formData.append("authKey", value.authKey || "");
    formData.append("authValue", value.authValue || "");
    formData.append("status", isSwitchOn === true ? "Active" : "Inactive");
    if (selectedFile.length) {
      formData.append("file", selectedFile[0]?.originFileObj);
    }
    if (value.type === "WHATSAPP") {
      formData.append("template_name", value.template_name);
      formData.append("lang_code", value.lang_code);
    }
    addNewGateway(formData)
      .then((res: any) => {
        setLoader(false);
        toast.success("Gateway Add Successful");
        history.push("/gateway");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const companyValue = params.id
    ? { label: gateway?.companyId, value: gateway?.companyId }
    : "";

  const formik = useFormik({
    initialValues: {
      gatewayName: params.id ? gateway?.gatewayName : "",
      companyId: companyValue,
      type: params.id ? gateway?.type : "SMS",
      connectionURL: params.id ? gateway?.connectionURL : "",
      loginName: params.id ? gateway?.loginName : "",
      password: params.id ? gateway?.password : "",
      port: params.id ? gateway?.port : "",
      otherKey: params.id ? gateway?.otherKey : "",
      recieverInfoURL: params.id
        ? gateway?.recieverInfoURL
          ? gateway?.recieverInfoURL
          : ""
        : "",
      authKey: params.id ? (gateway?.authKey ? gateway?.authKey : "") : "",
      authValue: params.id
        ? gateway?.authValue
          ? gateway?.authValue
          : ""
        : "",
      enabled: params.id ? Boolean(gateway?.enabled === "Active") : true,
      template_name: params.id ? gateway?.template_name : "",
      lang_code: params.id ? gateway?.lang_code : "",
    },
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  useEffect(() => {
    if (params.id) {
      getOneGatewayDetails();
    }
    getAllCompaniesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params?.id ? "Edit Gateway" : "New Gateway"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Gateway Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="gatewayName"
                    value={formik.values.gatewayName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Company</div>
              <div className="col-lg-8">
                <div className="form-group">
                  {/* <input
                    className="form-control"
                    id="title"
                    disabled={Boolean(params.id)}
                    name="companyId"
                    value={formik.values.companyId}
                    onChange={formik.handleChange}
                  /> */}

                  <CreatableSelect
                    options={companyList}
                    isClearable={false}
                    className="react-select"
                    classNamePrefix="select"
                    onChange={(opt: any) => {
                      formik.setFieldValue("companyId", opt);
                    }}
                    value={formik.values.companyId}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Type</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <select
                    className="form-control"
                    id="title"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <option value="SMS">SMS</option>
                    <option value="WHATSAPP">Whatsapp</option>
                    <option value="EMAIL">Email</option>
                    <option value="PUSH NOTIFICATION">
                      Push Notification (IOS & Android)
                    </option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "10px",
                    }}
                  />
                </div>
              </div>
            </div>

            {formik.values.type === "SMS" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">
                  Phone (twillio/firebase)
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="title"
                      name="otherKey"
                      value={formik.values.otherKey}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "EMAIL" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap ">Connection URL</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="connectionURL"
                      value={formik.values.connectionURL}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "PUSH NOTIFICATION" ? (
              <div className="col-lg-10 col-md-10 col-sm-12 ">
                <div className="col-lg-4 ws-nowrap">Firebase JSON</div>
                <div className="col-lg-12 ws-nowrap">
                  <div className="form-group">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "PUSH NOTIFICATION" ? null : (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">
                  {formik.values.type === "SMS"
                    ? "SID"
                    : formik.values.type === "WHATSAPP"
                    ? "PhoneNumberID"
                    : "Login Name"}
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="loginName"
                      value={formik.values.loginName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {formik.values.type === "PUSH NOTIFICATION" ? null : (
              <div
                className={
                  formik.values.type === "WHATSAPP"
                    ? "col-lg-12 col-md-12 col-sm-12"
                    : "col-lg-6 col-md-6 col-sm-12"
                }
              >
                <div className="col-lg-4 ws-nowrap">
                  {formik.values.type === "SMS"
                    ? "Auth Token"
                    : formik.values.type === "WHATSAPP"
                    ? "Access Token"
                    : "Password"}
                </div>
                <div
                  className={
                    formik.values.type === "WHATSAPP" ? "col-lg-10" : "col-lg-8"
                  }
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {formik.values.type === "WHATSAPP" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Template Name</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="template_name"
                      value={formik.values.template_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "WHATSAPP" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Language Code</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="lang_code"
                      value={formik.values.lang_code}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "EMAIL" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Port</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="port"
                      value={formik.values.port}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Reciever Info URL</div>
              <div className="col-lg-10">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="recieverInfoURL"
                    value={formik.values.recieverInfoURL}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Auth Header Key</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="authKey"
                    value={formik.values.authKey}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Auth Header Value</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="title"
                    name="authValue"
                    value={formik.values.authValue}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="enabled"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/gateway">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddGateway;
