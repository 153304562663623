import React, { FC } from "react";
// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

interface Props {
  paginationProps: any;
  handleSizePerPage: any;
  entities: any;
}

export const SizePerPage: FC<Props> = ({
  paginationProps,
  handleSizePerPage,
}) => {
  const { sizePerPageList, sizePerPage } = paginationProps;
  return (
    <div className="mt-2 mr-2">
      <div className="d-flex align-items-center">
        <div
          className="d-flex align-items-center ml-4 top-select"
          style={{ width: "6rem", position: "relative" }}
        >
          <select
            className="form-control border-0 custom-select"
            style={{ backgroundColor: "#F5F5F5" }}
            name="size-per-page"
            onChange={(e) => {
              e.preventDefault();

              handleSizePerPage(paginationProps, parseInt(e.target.value));
            }}
            value={sizePerPage}
          >
            {sizePerPageList.map((option: string, ind: number) => (
              <option key={ind} value={option}>
                {option}
              </option>
            ))}
          </select>
          {/* <ExpandMoreRoundedIcon
            style={{ position: "absolute", right: "1rem", zIndex: 10 }}
          /> */}
        </div>
      </div>
    </div>
  );
};
