import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import ApexChart from "react-apexcharts";
import { getReminderCharts } from "../../../../../service/dashboardServices";

const SuccessRateCard = () => {
  const [chartsData, setChartsData] = useState<any>([]);

  const fetchActiveGateways = () => {
    getReminderCharts()
      .then((res: any) => {
        const successCounts =
          res.data?.data.find((x: any) => x.status === "Sent")?.total || 0;
        const failCounts =
          res.data?.data.find((x: any) => x.status === "Fail")?.total || 0;
        const cancelCounts =
          res.data?.data.find((x: any) => x.status === "Cancel")?.total || 0;

        setChartsData([successCounts, failCounts, cancelCounts]);
      })
      .catch((err) => {});
  };

  const options: any = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: ["Sent", "Fail", "Cancel"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
  };

  useEffect(() => {
    fetchActiveGateways();
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <CardContent className="p-5">
        {/* <b
          className="m-0 text-uppercase cursor-pointer"
          onClick={() => {
            history.push("/gateway");
          }}
        >
          Active Gateways&nbsp;&nbsp;
          <i style={{ color: "#000" }} className="fa fa-chevron-right"></i>
        </b> */}
        <div className="d-flex justify-content-between mt-3">
          {/* <h2 className="m-0 font-weight-light">{chartsData || 0}</h2> */}
          <div>
            <ApexChart
              options={options}
              series={chartsData}
              type="donut"
              width={380}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SuccessRateCard;
