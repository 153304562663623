import React from "react";
import ApexChart from "react-apexcharts";

const SNChart = ({ options, series, height, type }) => {
  return (
    <div>
      <ApexChart
        options={options}
        series={series}
        type={type}
        height={height}
      />
    </div>
  );
};

export default SNChart;
