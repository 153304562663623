import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import {
  getOneCompany,
  addNewCompany,
  updateCompany,
} from "../../../service/companyService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import "react-phone-input-2/lib/style.css";

interface Values {
  name: string;
  phone: string;
  email: string;
  officePhone: string;
  cellPhone: string;
}

interface Params {
  id?: string;
}

const AddAPIKey = () => {
  const history = useHistory();
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();

  useEffect(() => {
    if (params.id) {
      getOneReceiverDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneReceiverDetails = () => {
    setLoader(true);
    getOneCompany(params?.id)
      .then((res: any) => {
        setLoader(false);
        setCompanyDetails(res?.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["phone"] = value.phone;
    requestBody["email"] = value.email;
    requestBody["officePhone"] = value.officePhone;
    requestBody["cellPhone"] = value.cellPhone;

    updateCompany(params.id, requestBody)
      .then((res: any) => {
        setLoader(false);

        toast.success("Company updated Successfully");
        history.push("/company");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["phone"] = value.phone;
    requestBody["email"] = value.email;
    requestBody["officePhone"] = value.officePhone;
    requestBody["cellPhone"] = value.cellPhone;

    addNewCompany(requestBody)
      .then((res: any) => {
        setLoader(false);
        toast.success("Company Added Successfully");
        history.push("/company");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: params.id ? companyDetails?.name : "",
      phone: params.id ? companyDetails?.phone : "",
      email: params.id ? companyDetails?.email : "",
      officePhone: params.id ? companyDetails?.officePhone : "",
      cellPhone: params.id ? companyDetails?.cellPhone : "",
    },
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params?.id ? "Edit Company" : "New Company"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Phone</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Email</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Office Phone</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="officePhone"
                    value={formik.values.officePhone}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Cell Phone</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="cellPhone"
                    value={formik.values.cellPhone}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/company">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddAPIKey;
