import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
// import ApexChart from "react-apexcharts";
import { getActiveGateways } from "../../../../../service/dashboardServices";

const GatewaysCard = () => {
  const history = useHistory();
  const [activeGateways, setActiveGateways] = useState(0);

  const fetchActiveGateways = () => {
    getActiveGateways()
      .then((res: any) => {
        setActiveGateways(res.data.total);
      })
      .catch((err) => {});
  };

  // const options = {
  //   chart: {
  //     // type: "line",
  //     width: 100,
  //     height: 35,
  //     sparkline: {
  //       enabled: true,
  //     },
  //   },
  //   tooltip: {
  //     fixed: {
  //       enabled: false,
  //     },
  //     x: {
  //       show: false,
  //     },
  //     y: {
  //       title: {
  //         formatter: function(seriesName) {
  //           return "";
  //         },
  //       },
  //     },
  //   },
  // };

  useEffect(() => {
    fetchActiveGateways();
    // eslint-disable-next-line
  }, []);
  return (
    <Card>
      <CardContent className="p-5">
        <b
          className="m-0 text-uppercase cursor-pointer"
          onClick={() => {
            history.push("/gateway");
          }}
        >
          Active Gateways&nbsp;&nbsp;
          <i style={{ color: "#000" }} className="fa fa-chevron-right"></i>
        </b>
        <div className="d-flex justify-content-between mt-3">
          <h2 className="m-0 font-weight-light">{activeGateways || 0}</h2>
          <div style={{ width: 150 }}>
            {/* <ApexChart
              options={options}
              series={[
                {
                  data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
                },
              ]}
              type="bar"
              height={35}
            /> */}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default GatewaysCard;
