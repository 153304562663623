import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import {
  getOneAdmin,
  addNewAdmin,
  updateAdmin,
} from "../../../../service/adminServices";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface Values {
  username: string;
  password: string;
}

interface Params {
  id?: string;
}

const AddUser = () => {
  const history = useHistory();
  const [userData, setUserData] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(true);

  useEffect(() => {
    if (params.id) {
      getOneUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneUserDetails = () => {
    setLoader(true);
    getOneAdmin(params?.id)
      .then((res: any) => {
        setLoader(false);
        setUserData(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "active"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["username"] = value.username;
    if (value.password && value.password !== "")
      requestBody["password"] = value.password;
    requestBody["status"] = isSwitchOn === true ? "active" : "disactivated";

    updateAdmin(params.id, requestBody)
      .then((res: any) => {
        setLoader(false);
        toast.success("Admin update Successful");
        history.push("/account/admin");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["username"] = value.username;
    // requestBody["phoneNumber"] = value.phoneNumber;
    if (value.password && value.password !== "")
      requestBody["password"] = value.password;

    addNewAdmin(requestBody)
      .then((res: any) => {
        setLoader(false);
        toast.success("Admin Add Successful");
        history.push("/account/admin");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      username: params.id ? userData?.username : "",
      password: "",
    },

    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params?.id ? "Edit Admin" : "New Admin"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Username</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Password</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="title"
                    // disabled={Boolean(params.id)}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {params.id && (
            <div className="row" style={{ marginLeft: "0.5px" }}>
              <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
                <span>Status</span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isSwitchOn}
                      onChange={onSwitchAction}
                      color="primary"
                      name="enabled"
                    />
                  }
                  label={isSwitchOn ? "Active" : "Inactive"}
                  className="mb-0"
                />
              </div>
            </div>
          )}
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/account/user">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddUser;
