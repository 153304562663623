import api from "../common/api";

// GET COMPANY GATEWAYS LIST
export const getDashboardCompanyGateways = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`dashboard/company-gateway`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

// GET ACTIVE REMINDERS
export const getActiveReminders = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`dashboard/active-reminders`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

// GET ACTIVE REMINDERS
export const getActiveGateways = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`dashboard/active-gateways`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

// GET REMINDERS CHART
export const getReminderCharts = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`dashboard/reminder-chart`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
