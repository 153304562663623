import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { AuthLogin } from "../../service/auth";
import Loader from "../../common/loader";
import { LOCAL_STORAGE_KEYS } from "../../common/constant/defalut";

const AuthLoginData = () => {
  const initialValues = {
    username: "",
    password: "",
  };

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("password is required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const login = async (values, { setStatus, setSubmitting }) => {
    setLoading(true);
    const body = {
      username: values.username,
      password: values.password,
    };
    AuthLogin(body)
      .then((res: any) => {
        const token = res?.data?.token;
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
        setLoading(false);
        setSubmitting(false);
        toast.success("User Login Success");
        history.push("/message");
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || "Invalid Username or Password";
        setLoading(false);
        setSubmitting(false);
        toast.error(message);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) =>
      login(values, { setStatus, setSubmitting }),
  });

  return (
    <div
      className="login-form login-signin col-8 col-md-6 mx-auto"
      id="kt_login_signin_form"
    >
      {loading && <Loader />}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login</h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container w-100">
          <input
            placeholder="Username"
            type="text"
            className={`form-control form-control-solid h-auto py-3`}
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-3 ${getInputClasses(
              "password"
            )}`}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthLoginData;
