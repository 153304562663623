import api from "../common/api";

export const AuthLogin = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post("users/login", payload)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const CheckLoginStatus = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken("users/user")
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
