import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import RemindersCard from "./Widgets/Reminders";
import GatewaysCard from "./Widgets/Gateways";
import SuccessRateCard from "./Widgets/Reminders/SuccessRate";
import { getDashboardCompanyGateways } from "../../../service/dashboardServices";
import ApexChart from "react-apexcharts";

const Dashboard = () => {
  const [companyGateways, setCompanyGateways] = useState([]);

  const xAxisItems = companyGateways.map((c: any) => c.companyId);
  const gateways = companyGateways.map((c: any) => c.total);

  const options: any = {
    chart: {
      width: 100,
      zoom: {
        enabled: false,
      },
      // height: 35,
      // sparkline: {
      //   enabled: true,
      // },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xAxisItems,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function(seriesName) {
            return "";
          },
        },
      },
    },
  };

  const fetchCompanyGateways = () => {
    getDashboardCompanyGateways()
      .then((res: any) => {
        setCompanyGateways(res?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchCompanyGateways();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <RemindersCard />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <GatewaysCard />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <SuccessRateCard />
        </Grid>
        {/* <Grid item lg={3} md={6} sm={6} xs={12}></Grid> */}

        <Grid item lg={12} spacing={3}>
          <Card>
            <CardContent className="p-5">
              <Grid container>
                <Grid item lg={9} className="p-2">
                  {/* <h1>Chart</h1> */}

                  <ApexChart
                    options={options}
                    series={[
                      {
                        data: gateways,
                      },
                    ]}
                    type="bar"
                    // height={35}
                  />
                </Grid>

                <Grid item lg={3} className="p-2 pl-10">
                  <h1 className="pb-1">Companies</h1>
                  <div style={{ maxHeight: 545, overflow: "auto" }}>
                    {companyGateways.map((item: any, index) => {
                      const randomColor: any = Math.floor(
                        Math.random() * 16777215
                      ).toString(16);
                      return (
                        <div key={index} className="pl-1 mb-2">
                          <p className="m-0">
                            <i
                              className="fa fa-circle mr-2"
                              style={{ fontSize: 10, color: `#${randomColor}` }}
                              aria-hidden="true"
                            ></i>
                            {item?.companyId}
                          </p>
                          <span className="pl-5">
                            {item?.total || 0} Gateways
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
