import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import SearchSelect from "react-select";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import {
  getOneReceiver,
  addNewReceiver,
  updateReceiver,
} from "../../../service/receiverService";
import { getAllCompanies } from "../../../service/companyService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface Values {
  name: string;
  companyId: string;
  phone: string;
  email: string;
}

interface Params {
  id?: string;
}

const AddAPIKey = () => {
  const history = useHistory();
  const [receiver, setReceiver] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<any>([]);

  const getCompanyList = () => {
    getAllCompanies()
      .then((res: any) => {
        const newOptions = res?.data?.data?.map((x) => {
          return {
            label: x?.name,
            value: x?.name,
          };
        });
        setCompanyList(newOptions || []);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (params.id) {
      getOneReceiverDetails();
    }
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneReceiverDetails = () => {
    setLoader(true);
    getOneReceiver(params?.id)
      .then((res: any) => {
        setLoader(false);
        setReceiver(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "ACTIVE"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["companyId"] = value.companyId;
    requestBody["phone"] = value.phone;
    requestBody["email"] = value.email;
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";
    updateReceiver(params.id, requestBody)
      .then((res: any) => {
        setLoader(false);

        toast.success("Receiver updated Successfully");
        history.push("/receivers");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["companyId"] = value.companyId;
    requestBody["phone"] = value.phone;
    requestBody["email"] = value.email;
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";

    addNewReceiver(requestBody)
      .then((res: any) => {
        setLoader(false);
        toast.success("Receiver Added Successfully");
        history.push("/receivers");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: params.id ? receiver?.name : "",
      companyId: params.id ? receiver?.companyId : "",
      phone: params.id ? receiver?.phone : "",
      email: params.id ? receiver?.email : "",
    },
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params?.id ? "Edit Reciever" : "New Reciever"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Company</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <SearchSelect
                    options={companyList}
                    value={companyList.find(
                      (x: any) => x.value === formik.values.companyId
                    )}
                    onChange={(opt: any) => {
                      if (opt) {
                        formik.setFieldValue("companyId", opt.value);
                      } else {
                        formik.setFieldValue("companyId", "");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    disabled={Boolean(params.id)}
                    className="form-control"
                    id="title"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Phone</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <PhoneInput
                    country={"hk"}
                    inputStyle={{ width: "100%" }}
                    value={formik.values.phone}
                    onChange={(phone) => {
                      formik.setFieldValue("phone", `+${phone}`);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Email</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <div className="col-lg-4 ws-nowrap ">Status</div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="enabled"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/receivers">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddAPIKey;
