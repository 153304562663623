import React, { useEffect } from 'react'
import qs from "querystring"
import { saveRedirection } from "../service/redirection"

const RedirectPage = (props) => {
    const params: any = qs.parse(props.location.search)
    const originalURL: any = params["?url"] || ''
    const originalEmail: any = params["i"]
    const origialReminderID: any = params["ll"]
    const origialMedia: any = params["mi"]

    useEffect(() => {
        const payload = {
            messageId: origialReminderID,
            receiver: window.atob(originalEmail),
            media: origialMedia
        }
        saveRedirection(payload)
        window.location = originalURL
        // eslint-disable-next-line
    }, [window.location.pathname])
    return (
        <div>Redirecting...</div>
    )
}
// http://localhost:3005/redirect?url=https://instagram.com&i=cGF0ZWwuamlnYXI4MDA1QGdtYWlsLmNvbQ==&ll=63d1104318db47a241cb7bbd
export default RedirectPage