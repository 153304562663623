import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import moment from "moment";
import SearchSelect from "react-select";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getOneKey, addNewKey, updateKey } from "../../../service/keyServices";
import { getAllCompanies } from "../../../service/companyService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
interface Values {
  keyName: string;
  companyId: string;
  type: string;
  expiredDate: string;
}

interface Params {
  id?: string;
}

const AddAPIKey = () => {
  const history = useHistory();
  const [key, setKey] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<any>([]);

  const getCompanyList = () => {
    getAllCompanies()
      .then((res: any) => {
        let optionVal = res.data.data.map((x: any) => {
          return {
            label: x?.name,
            value: x?.name,
          };
        });
        setCompanyList(optionVal);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (params.id) {
      getOneKeyDetails();
    }
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneKeyDetails = () => {
    setLoader(true);
    getOneKey(params?.id)
      .then((res: any) => {
        setLoader(false);
        setKey(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "ACTIVE"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["keyName"] = value.keyName;
    requestBody["companyId"] = value.companyId;
    requestBody["type"] = value.type;
    requestBody["expiredDate"] = value.expiredDate;
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";
    updateKey(params.id, requestBody)
      .then((res: any) => {
        setLoader(false);

        toast.success("Key update Successful");
        history.push("/key");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const requestBody = {};
    requestBody["keyName"] = value.keyName;
    requestBody["companyId"] = value.companyId;
    requestBody["type"] = value.type;
    requestBody["expiredDate"] = value.expiredDate;
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";

    addNewKey(requestBody)
      .then((res: any) => {
        setLoader(false);
        toast.success("Key Add Successful");
        history.push("/key");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const BranchSchema = Yup.object().shape({
    // status: Yup.string().required("status is required"),
    phone: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      keyName: params.id ? key?.keyName : "",
      companyId: params.id ? key?.companyId : "",
      type: params.id ? key?.type : "SENDER",
      expiredDate: params.id
        ? key?.expiredDate
          ? moment(key?.expiredDate).format("YYYY-MM-DD")
          : ""
        : "",
    },
    validationSchema: BranchSchema,
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader title={params?.id ? "Edit Key" : "New Key"}></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Key Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    disabled={Boolean(params.id)}
                    className="form-control"
                    id="title"
                    name="keyName"
                    value={formik.values.keyName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Company</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <SearchSelect
                    options={companyList}
                    value={companyList.find(
                      (x: any) => x.value === formik.values.companyId
                    )}
                    onChange={(opt: any) => {
                      if (opt) {
                        formik.setFieldValue("companyId", opt.value);
                      } else {
                        formik.setFieldValue("companyId", "");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {params.id && (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Type</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="title"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                    >
                      <option value="SENDER">Sender</option>
                      <option value="RECIEVER">Reciever</option>
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap ">Expiry Date</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control"
                    id="title"
                    name="expiredDate"
                    value={formik.values.expiredDate}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <div className="col-lg-4 ws-nowrap ">Status</div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="enabled"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/key">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddAPIKey;
