import React, { useState, useEffect } from "react";
import { getMessageLog } from "../../../service/messageService";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import moment from "moment";

const StatusReason = ({ messageId }) => {
  const history = useHistory();
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortingType] = useState<any>("");

  const columns = [
    {
      dataField: "action",
      text: "Action",
    },
    {
      dataField: "log",
      style: { maxWidth: "250px", wordBreak: "break-word" },
      text: "Log",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
  ];

  const getMessageLogsList = (
    page = 1,
    sizePerPage = 10,
    showLoading = true
  ) => {
    const isStatus = "";
    const isSortColumn =
      sortingType !== ""
        ? `&${sortingType}`
        : `&sortOrder=desc&sortField=createdAt`;

    getMessageLog(page, sizePerPage, isStatus, isSortColumn, messageId)
      .then((res: any) => {
        const listData = res?.data?.docs.map((data: any, index: any) => {
          return {
            _id: data._id,
            index: ++index + (page - 1) * sizePerPage,
            action: data.action,
            log:
              data.errorLog && data?.errorLog !== ""
                ? data?.errorLog
                : data?.successLog,
            status: data?.status,
            createdAt: moment(data?.createdAt).format("YYYY-MM-DD"),
          };
        });

        setEntities(listData);
        setTotalData(res?.data?.totalDocs);
      })
      .catch((err) => {
        history.push("/auth/login");
      });
  };

  useEffect(() => {
    getMessageLogsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingType]);

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getMessageLogsList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getMessageLogsList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
  };

  useEffect(() => {
    getMessageLogsList(1, 10, false);
    // eslint-disable-next-line
  }, []);

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <Table
            getSelectRow={getSelectRow}
            paginationProps={paginationProps}
            paginationOptions={paginationOptions}
            paginationTableProps={paginationTableProps}
            data={entities}
            columns={columns}
            remote
            onTableChange={(type: any, props: any) =>
              onTableChange(type, props, paginationProps)
            }
          />
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <PaginationLinks paginationProps={paginationProps} />
            </div>
            <div className="d-flex">
              <SizePerPage
                paginationProps={paginationProps}
                handleSizePerPage={handleSizePerPage}
                entities={entities}
              />
              <PaginationToolbar paginationProps={paginationProps} />
            </div>
          </div>
        </>
      )}
    </PaginationProvider>
  );
};

export default StatusReason;
