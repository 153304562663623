import axios from "axios";
import { DevURL } from "../constant/defalut";
import { LOCAL_STORAGE_KEYS } from "../constant/defalut";

export const Bearer = "Bearer ";

const axoisAPI = {
  post: (url: any, data?: any) => {
    return axios.post(DevURL + url, data, { withCredentials: true });
  },

  get: (url: any) => {
    return axios.get(DevURL + url, { withCredentials: true });
  },

  postwithToken: (url: any, data?: any) => {
    return axios.post(DevURL + url, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  putwithToken: (url?: any, data?: any) => {
    return axios.put(DevURL + url, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  getwithToken: (url: any) => {
    return axios.get(DevURL + url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  deletewithToken: (url: any) => {
    return axios.delete(DevURL + url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },
};

export default axoisAPI;
