/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
// import api from "../../../../common/api";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const Logout = () => {
    localStorage.clear();
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <i className="fa fa-tachometer p-2"></i>
            </span>
            <span className="menu-text ml-3">Dashboard</span>
          </NavLink>
        </li>

        <li className="py-3">
          <span className="text-uppercase Aside-header  menu-text ml-4 my-5">
            Main Service
          </span>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/message", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/message">
            <span className="svg-icon menu-icon">
              <i className="fas fa-school p-2"></i>
            </span>
            <span className="menu-text ml-3">Reminder</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/edm", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/edm">
            <span className="svg-icon menu-icon">
              <i className="fas fa-school p-2"></i>
            </span>
            <span className="menu-text ml-3">EDM</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/receivers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/receivers">
            <span className="svg-icon menu-icon">
              <i className="fas fa-receipt p-2"></i>
            </span>
            <span className="menu-text ml-3">Receiver</span>
          </NavLink>
        </li>

        <li className="py-3">
          <span className="text-uppercase Aside-header  menu-text ml-4 my-5">
            Marketing Service
          </span>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/edm", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/edm">
            <span className="svg-icon menu-icon">
              <i className="fas fa-envelope  p-2"></i>
            </span>
            <span className="menu-text">Email</span>
          </NavLink>
        </li>

        <li className="py-3">
          <span className="text-uppercase Aside-header menu-text ml-4">
            Account
          </span>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/account/admin", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account/admin">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user  p-2"></i>
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/account/user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account/user">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user  p-2"></i>
            </span>
            <span className="menu-text">User</span>
          </NavLink>
        </li>

        <li className="py-3">
          <span className="text-uppercase Aside-header menu-text ml-4">
            Setting
          </span>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/approval",
            true
          )}${getMenuItemActive("/company", true)}${getMenuItemActive(
            "/gateway",
            true
          )}${getMenuItemActive("/key", true)}${getMenuItemActive(
            "/setting/edm-header-footer/",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <i className="fas fa-gear  p-2"></i>
            </span>
            <span className="menu-text ml-3">Setting</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive("/company", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/company">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Company</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/gateway", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/gateway">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Gateway</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/key", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/key">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Key</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/setting/edm-header-footer/",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/setting/edm-header-footer/">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">EDM Header Footer</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* =========logout=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/auth/login", false)}`}
          aria-haspopup="true"
          onClick={Logout}
        >
          <NavLink className="menu-link" to="/auth/login">
            <span className="svg-icon menu-icon">
              <i className="fa fa-sign-out p-2"></i>
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
