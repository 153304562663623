import React from 'react'
import { Table } from "../../../common/table";

const ViewClickModal = ({ data }) => {
    const columns = [
        {
            dataField: "index",
            text: "Index",
        },
        {
            dataField: "user",
            text: "User",
        }
    ];

    return (
        <Table
            data={data}
            columns={columns}
        />
    );
};

export default ViewClickModal;
