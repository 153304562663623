import api from "../common/api";

export const getUsers = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`users?page=${page}&limit=${limit}${q}${type}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneUser = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`users/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewUser = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`users/create-user`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateUser = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`users/edit-user/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteUser = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`users/delete-user/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
