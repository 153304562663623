import React, { FC } from "react";
import BootstrapTable from "react-bootstrap-table-next";
interface Props {
  data: any;
  getSelectRow?: any;
  columns: any;
  approveBtn?: any;
  paginationProps?: any;
  paginationOptions?: any;
  expandRow?: any;
  paginationTableProps?: any;
  remote?: boolean;
  onTableChange?: any;
  keyField?: string;
  rowEvents?: any;
}

export const Table: FC<Props> = (props) => {
  const {
    data,
    getSelectRow,
    columns,
    approveBtn,
    paginationTableProps,
    expandRow,
    remote,
    onTableChange,
    keyField,
    rowEvents,
  } = props;

  return (
    <div>
      <BootstrapTable
        // wrapperClasses="table-responsive"
        bordered={false}
        classes={`mt-4 table table-head-custom table-vertical-center ${data.length >
          0 && "row-hover"}`}
        rowEvents={rowEvents || {}}
        bootstrap4
        defaultSorted={[
          {
            dataField: "id",
            order: "asc",
          },
        ]}
        // @ts-ignore
        keyField={Boolean(keyField) ? keyField : "id"}
        // @ts-ignore
        data={data === null ? [] : data}
        // @ts-ignore
        columns={columns}
        selectRow={getSelectRow}
        remote={Boolean(remote)}
        expandRow={expandRow ? expandRow : {}}
        onTableChange={onTableChange}
        noDataIndication="No data found"
        {...paginationTableProps}
      />

      {Boolean(approveBtn) && approveBtn}
    </div>
  );
};
