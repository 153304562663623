import api from "../common/api";

export const getKeys = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`keys?page=${page}&limit=${limit}${q}${type}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneKey = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`keys/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewKey = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`keys/create`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateKey = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`keys/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
