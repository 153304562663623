import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import {
  getOneMessage,
  getMessagesByGroupID,
  getGroupMessages,
  deleteMessage,
  deleteMessageWithGroup,
  updateMultipleStatus,
} from "../../../service/messageService";
import { getMultipleCompanies } from "../../../service/gatewayServices";
import { toast } from "react-toastify";
import _ from "lodash";
import { getCompanies } from "../../../service/gatewayServices";
import { Link } from "react-router-dom";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../../common/loader";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { useHistory } from "react-router";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import StatusReason from "./statusReason";

const getMinutes = (time = "") => {
  const timeUTCval = moment(`2022-01-01T${time}:00Z`).format("HH:mm");
  return timeUTCval;
};

const Message: FC = () => {
  const history = useHistory();
  const [show, setShow] = useState<any>(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("Schedule");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [sortingType, setSortingType] = useState<any>("");
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState("");
  const [scheduleDate, setScheduleDate] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedGroupId, setExpandedGroupId] = useState("");
  const [expandedMsgId, setExpandedMsgId] = useState("");
  const [selectedReasonMessageId, setSelectedReasonMessageId] = useState<any>(
    ""
  );

  // SET COMPANY NAME INTO GROUP MESSAGE
  const setCompanyToGroupMessages = (records: any, ids?: any) => {
    getMultipleCompanies(_.uniq(ids))
      .then((res: any) => {
        const newEntities = records.map((x: any) => {
          return {
            ...x,
            companyId:
              res?.data?.find((y: any) => y?._id === x.gatewayId)?.companyId ||
              "",
          };
        });
        setEntities(newEntities);
      })
      .catch((err) => {
      });
  };

  // MESSAGE LIST QUERIES
  const [mEntities, setmEntities] = useState<any>([]);
  const [mPage, setMpage] = useState(1);
  const [mSizePerPage, setMSizePerPage] = useState(10);
  const [mTotalData, setMTotalData] = useState<number>(1);
  const [newSelectedStatus, setNewSelectedStatus] = useState("");
  const [updateModal, setUpdateModal] = useState(false);

  const getCompanyList = () => {
    getCompanies()
      .then((res: any) => {
        let optionVal = res.data.data.map((x: any) => {
          return {
            label: x?.companyId,
            value: x?.companyId,
          };
        });
        optionVal = [{ label: "All", value: "" }, ...optionVal];
        setCompanyList(optionVal);
      })
      .catch((err) => { });
  };

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${status === "Schedule" || status === "Sent"
            ? `success`
            : status === "Cancel"
              ? "warning"
              : `danger`
            } label-inline font-weight-normal font-size-11`}
        >
          {status}
        </span>
      );
    }
  };
  const openEditReminder = (id) => {
    history.push(`/message/update/${id}`);
  };

  // GROUP ACTIONS
  const GroupActions = (cellContent: any, row: any, rowIndex: any) => {
    return row?.status !== "Cancel" ? (
      <>
        <div
          title="Delete Gateway"
          className="btn btn-icon btn-light btn-sm"
          onClick={() => {
            setSelectedId(row);
            setShow(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/trash.svg")}
            />
          </span>
        </div>
        {row.groupId ? (
          <div
            title="Delete Gateway"
            className="btn btn-icon btn-light btn-sm ml-2"
            onClick={() => {
              if (row.groupId) {
                history.push(`/message/clone/${row.groupId}`);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/copy.svg")}
              />
            </span>
          </div>
        ) : null}
      </>
    ) : null;
  };

  // FOR MESSAGES
  const ActionEdit = (cellContent: any, row: any, rowIndex: any) => {
    return (
      <>
        <div
          role="button"
          title="Edit Reminder"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditReminder(row._id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </div>
        {row?.status !== "Cancel" ? (
          <div
            title="Delete Gateway"
            className="btn btn-icon btn-light btn-sm mx-3"
            onClick={() => {
              setSelectedId(row);
              setShow(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/trash.svg")}
              />
            </span>
          </div>
        ) : null}

        <div
          title="Success / Fail Reason"
          className="btn btn-icon btn-light btn-sm mx-3"
          onClick={() => {
            setSelectedReasonMessageId(row._id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/warning.svg")}
            />
          </span>
        </div>
      </>
    );
  };

  // COLUMNS FOR CHILD REMINDERS
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "scheduleTime",
      text: "Schedule Time",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      // onSort: (field: string, order: string) =>
      //   sortServerString(field, order, setSortingType),
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      // onSort: (field: string, order: string) =>
      //   sortServerString(field, order, setSortingType),
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      // onSort: (field: string, order: string) =>
      //   sortServerString(field, order, setSortingType),
    },
    {
      dataField: "recieverInfo",
      text: "Reciever",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      style: { maxWidth: "150px" },
      formatter: (cell) => {
        return cell.join(", ");
      },
      // onSort: (field: string, order: string) =>
      //   sortServerString(field, order, setSortingType),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      // headerSortingClasses,
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "successRate",
      text: "Success Rate",
      sort: false,
      // headerSortingClasses,
      formatter: (cell, row) => {
        return `${cell}/${row?.recieverInfo.length}`;
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: ActionEdit,
    },
  ];

  // COLUMNS FOR GROUP MESSAGE
  const grpColumns = [
    {
      dataField: "groupId",
      text: "Group ID",
      style: { maxWidth: "150px" },
      // formatter: (cell, row) => {
      //   return row?.groupId ? row?.groupId : row?._id;
      // },
    },
    {
      dataField: "companyId",
      text: "Company",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      style: { maxWidth: "150px" },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: GroupActions,
    },
  ];

  // TO GET GROUP MSG LIST
  const getGroupMsgList = (page = 1, sizePerPage = 10, showLoading = true) => {
    const isStatus =
      type !== "" && selectedGateway !== "" && company !== ""
        ? `&filter={"status":"${type}","messageType":"${selectedGateway}","gatewayData.companyId":"${company}"}`
        : type !== "" && selectedGateway === "" && company === ""
          ? `&filter={"status":"${type}"}`
          : selectedGateway !== "" && company === "" && type === ""
            ? `&filter={"messageType":"${selectedGateway}"}`
            : selectedGateway === "" && company !== "" && type === ""
              ? `&filter={"gatewayData.companyId":"${company}"}`
              : type !== "" && selectedGateway !== "" && company === ""
                ? `&filter={"status":"${type}","messageType":"${selectedGateway}"}`
                : type !== "" && selectedGateway === "" && company !== ""
                  ? `&filter={"status":"${type}","gatewayData.companyId":"${company}"}`
                  : type === "" && selectedGateway !== "" && company !== ""
                    ? `&filter={"messageType":"${selectedGateway}","gatewayData.companyId":"${company}"}`
                    : "";
    const scheduleFilter =
      scheduleDate && scheduleDate !== ""
        ? `&scheduleDate=${scheduleDate}`
        : "";
    const isSortColumn =
      sortingType !== ""
        ? `&${sortingType}`
        : `&sortOrder=desc&sortField=createdAt`;

    if (showLoading) {
      setLoading(true);
    }
    getGroupMessages(
      page,
      sizePerPage,
      query,
      isStatus,
      isSortColumn,
      scheduleFilter
    )
      .then((res: any) => {
        setLoading(false);

        if (res?.data?.docs.length === 0 && page !== 1) {
          getGroupMsgList(page - 1);
          setPage(page - 1);
          return;
        }

        const listData = res?.data?.docs.map((data: any, index: any) => {
          return {
            _id: data._id,
            index: ++index + (page - 1) * sizePerPage,
            recieverInfo: data?.recieverInfo,
            title: data?.title,
            groupId: data?.groupId, // data?.groupId === "N/A" ? "No Repeat" : data?.groupId
            repeat: data?.repeat,
            gatewayId: data?.gatewayId,
            // data?.groupId === "N/A" ? { companyId: "" } : data?.gatewayData,
            scheduleTime: `${moment(`${data?.scheduleTime}`).format(
              "YYYY-MM-DD"
            )} ${getMinutes(data?.sendTime)}`,
            // companyId:
            //   data?.groupId === "N/A" ? "N/A" : data?.groupId?.companyId,
            type: data?.messageType,
            status: data?.status,
            createdAt: moment(data?.createdAt).format("YYYY-MM-DD HH:mm"),
          };
        });
        const gatewayIDS: any = res?.data?.docs.map((x: any) => x?.gatewayId);
        setCompanyToGroupMessages(listData, gatewayIDS);
        setEntities(listData);
        setTotalData(res?.data?.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        history.push("/auth/login");
      });
  };

  // TO GET MESSAGE LIST BASED ON GROUP ID
  const getMessageList = (mPage = 1, mSizePerPage = 10, showLoading = true) => {
    const isStatus =
      type !== "" && selectedGateway !== "" && company !== ""
        ? `&filter={"status":"${type}","messageType":"${selectedGateway}","gatewayData.companyId":"${company}"}`
        : type !== "" && selectedGateway === "" && company === ""
          ? `&filter={"status":"${type}"}`
          : selectedGateway !== "" && company === "" && type === ""
            ? `&filter={"messageType":"${selectedGateway}"}`
            : selectedGateway === "" && company !== "" && type === ""
              ? `&filter={"gatewayData.companyId":"${company}"}`
              : type !== "" && selectedGateway !== "" && company === ""
                ? `&filter={"status":"${type}","messageType":"${selectedGateway}"}`
                : type !== "" && selectedGateway === "" && company !== ""
                  ? `&filter={"status":"${type}","gatewayData.companyId":"${company}"}`
                  : type === "" && selectedGateway !== "" && company !== ""
                    ? `&filter={"messageType":"${selectedGateway}","gatewayData.companyId":"${company}"}`
                    : "";
    const scheduleFilter =
      scheduleDate && scheduleDate !== ""
        ? `&scheduleDate=${scheduleDate}`
        : "";
    const isSortColumn =
      sortingType !== ""
        ? `&${sortingType}`
        : `&sortOrder=asc&sortField=scheduleTime`;

    if (showLoading) {
      setLoading(true);
    }
    if (expandedGroupId && expandedGroupId !== "") {
      getMessagesByGroupID(
        mPage,
        mSizePerPage,
        query,
        isStatus,
        isSortColumn,
        scheduleFilter,
        expandedGroupId
      )
        .then((res: any) => {
          setLoading(false);

          if (res?.data?.docs.length === 0 && mPage !== 1) {
            getMessageList(mPage - 1);
            setMpage(mPage - 1);
            return;
          }

          const listData = res?.data?.docs?.map((data: any, index: any) => {
            return {
              _id: data._id,
              index: ++index + (mPage - 1) * mSizePerPage,
              recieverInfo: data?.recieverInfo,
              title: data?.title,
              // groupId: data?.groupId || "N/A",
              repeat: data?.repeat,
              successRate: data?.successRate || 0,
              gatewayId: data?.gatewayData,
              scheduleTime: `${moment(`${data?.scheduleTime}`).format(
                "YYYY-MM-DD"
              )} ${getMinutes(data?.sendTime)}`,
              companyId: data?.gatewayId?.companyId,
              type: data?.messageType,
              status: data?.status,
              createdAt: moment(data?.createdAt).format("YYYY-MM-DD HH:mm"),
            };
          });

          setmEntities(listData);
          setMTotalData(res?.data?.totalDocs);
        })
        .catch((err) => {
          setLoading(false);
          history.push("/auth/login");
        });
    }
  };

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FOR GROUP
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  // FOR MESSAGES
  const customMTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {mTotalData === 0 ? 0 : (mPage - 1) * mSizePerPage + 1} to{" "}
      {to} of {mTotalData}
    </span>
  );

  // FOR GROUP LIST
  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  // FOR MESSAGE LIST
  const paginationMOptions = {
    custom: true,
    totalSize: mTotalData,
    hideSizePerPage: true,
    showTotal: true,
    mPage,
    mSizePerPage,
    paginationTotalRenderer: customMTotal,
  };

  // FOR GROUP
  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getGroupMsgList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getGroupMsgList(props.page, props.sizePerPage);
      }
    }
  };

  // FOR MESSAGE
  const onMTableChange = async (
    type: any,
    props: any,
    paginationProps: any
  ) => {
    if (type !== "sort") {
      setMSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setMpage(1);
        getMessageList(1, props.sizePerPage);
        setMpage(1);
      } else {
        setMpage(props.page);
        getMessageList(props.page, props.sizePerPage);
      }
    }
  };

  // FOR GROUP LIST
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  // FOR MESSAGES
  const handleMSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const handleOnSelect = (row: any, isSelect: any) => {
    if (isSelect) {
      const valll: any = [...selectedRows, row._id];
      setSelectedRows(valll);
    } else {
      setSelectedRows(() => selectedRows.filter((x) => x !== row._id));
    }
  };

  const handleOnSelectAll = (isSelect: any, rows: any) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  // FOR GROUP
  const getSelectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    selected: selectedRows,
    hideSelectColumn: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  // FOR MESSAGES
  const getMessageSelectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const rowEvents: any = {
    onDoubleClick: (e: any, row: any, rowIndex: any) => {
      history.push(`/message/update/${row._id}`);
    },
  };

  useEffect(() => {
    getGroupMsgList(1, 10, false);
    // eslint-disable-next-line
  }, [query, type, selectedGateway, company, scheduleDate, sortingType]);

  // DELETE MESSAGE
  const deleteMessageAction = () => {
    if (selectedId?.groupId) {
      deleteMessageWithGroup(
        selectedId?.groupId === "N/A" ? "null" : selectedId?.groupId
      )
        .then((res: any) => {
          if (res?.status === 200) {
            setTimeout(() => {
              getGroupMsgList(page);
              setShow(false);
              setSelectedId(null);
              setPage(page);
              setSizePerPage(10);
            }, 1000);
          }
        })
        .catch((err) => { });
    } else {
      deleteMessage(selectedId._id)
        .then((res: any) => {
          if (res?.status === 200) {
            setTimeout(() => {
              setShow(false);
              setSelectedId(null);
              if (expandedGroupId) {
                getMessageList(mPage);
              }
              setMpage(mPage);
              setMSizePerPage(10);
              getGroupMsgList(page);
              setPage(page);
              setSizePerPage(10);
              toast.success("Reminder Deleted Successfully");
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  // MULTIPLE STATUS UPDATE
  const multipleStatusUpdateAction = () => {
    updateMultipleStatus({ ids: selectedRows, status: newSelectedStatus })
      .then((res: any) => {
        if (res?.status === 200) {
          setTimeout(() => {
            setNewSelectedStatus("");
            setSelectedRows([]);
            setUpdateModal(false);

            if (expandedGroupId) {
              getMessageList(mPage);
            }
            setMpage(mPage);
            setMSizePerPage(10);
            getGroupMsgList(page);
            setPage(page);
            setSizePerPage(10);
            toast.success("Status updated Successfully");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const expandRow = {
    onlyOneExpanding: true,
    parentClassName: "parent-expand-messages",
    renderer: (row: any) => {
      setTimeout(() => {
        if (row?.groupId && row?.groupId !== null && row?.groupId !== "null") {
          // row?.groupId === "No Repeat" ? "null" : row?.groupId
          setExpandedGroupId(row?.groupId);
          setExpandedMsgId("");
        } else {
          setExpandedGroupId("");
          setExpandedMsgId(row?._id);
        }
      }, 100);
      return (
        <PaginationProvider pagination={paginationFactory(paginationMOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <div className="expanded-table-data">
              <Table
                keyField="_id"
                rowEvents={rowEvents}
                getSelectRow={getMessageSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationMOptions}
                paginationTableProps={paginationTableProps}
                data={mEntities || []}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onMTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleMSizePerPage}
                    entities={mEntities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
      );
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return null;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <i
            style={{ width: 30 }}
            className="fa fa-caret-down row-expand-icon"
          ></i>
        );
      }
      return (
        <i
          className="fa fa-caret-right row-expand-icon"
          style={{ width: 30 }}
        ></i>
      );
    },
    showExpandColumn: true,
  };

  useEffect(() => {
    setSelectedRows([]);
    if (expandedGroupId && expandedGroupId !== "") {
      setMpage(1);
      setMSizePerPage(10);
      setmEntities([]);
      setMTotalData(1);
      getMessageList(1, 10, false);
    } else {
      setMpage(1);
      setMSizePerPage(10);
      setmEntities([]);
      setMTotalData(1);
    }
    // eslint-disable-next-line
  }, [query, type, selectedGateway, company, scheduleDate, expandedGroupId]);

  useEffect(() => {
    if (expandedMsgId && expandedMsgId !== "") {
      getOneMessage(expandedMsgId)
        .then((res: any) => {
          const data: any = res.data;
          const singleMsg: any = {
            _id: data._id,
            recieverInfo: data?.recieverInfo,
            title: data?.title,
            repeat: data?.repeat,
            successRate: data?.successRate || 0,
            gatewayId: data?.gatewayData,
            scheduleTime: `${moment(`${data?.scheduleTime}`).format(
              "YYYY-MM-DD"
            )} ${getMinutes(data?.sendTime)}`,
            companyId: data?.gatewayId?.companyId,
            type: data?.messageType,
            status: data?.status,
            createdAt: moment(data?.createdAt).format("YYYY-MM-DD HH:mm"),
          };
          setmEntities([singleMsg]);
        })
        .catch((err: any) => { });
    }
    // eslint-disable-next-line
  }, [expandedMsgId]);

  return (
    <Card>
      {/* SINGLE/GROUP DELETE */}
      <Modal
        show={show}
        onHide={() => {
          setSelectedId(null);
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Body>
          {selectedId?.groupId
            ? "Please confirm delete all Reminder in the Group"
            : "Please confirm delete this reminder."}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setShow(false);
                setSelectedId(null);
              }}
              className="btn btn-secondary btn-elevate"
            >
              Close
            </button>
            <> </>
            <button
              type="button"
              className="btn btn-danger btn-elevate"
              onClick={deleteMessageAction}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* STATUS REASON MODAL */}
      <Modal
        size="lg"
        show={selectedReasonMessageId !== ""}
        onHide={() => {
          setSelectedReasonMessageId("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>Status Reason</Modal.Header>
        <Modal.Body>
          <StatusReason messageId={selectedReasonMessageId} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setSelectedReasonMessageId("");
              }}
              className="btn btn-secondary btn-elevate"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* STATUS UPDATE MODAL */}
      <Modal
        show={updateModal}
        onHide={() => {
          setNewSelectedStatus("");
          setSelectedRows([]);
          setUpdateModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Body>
          Please Confirm update {selectedRows?.length || 0} Reminder record
          status to {newSelectedStatus}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setNewSelectedStatus("");
                setSelectedRows([]);
                setUpdateModal(false);
              }}
              className="btn btn-secondary btn-elevate"
            >
              Close
            </button>
            <> </>
            <button
              type="button"
              className="btn btn-danger btn-elevate"
              onClick={multipleStatusUpdateAction}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {loading && <Loader />}
      <CardHeader title="Reminder">
        <div className="d-flex align-items-center">
          <div
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getGroupMsgList();
              getMessageList();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row w-100 flex-sm-column mb-2">
                  <FilterQuery
                    query={scheduleDate}
                    type="date"
                    label="Schedule Date"
                    setQuery={(e) => {
                      setScheduleDate(e);
                      setPage(1);
                    }}
                  />
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Schedule", value: "Schedule" },
                      { label: "Cancel", value: "Cancel" },
                      { label: "Fail", value: "Fail" },
                      { label: "Sent", value: "Sent" },
                    ]}
                  />
                  <StatusFilter
                    status={selectedGateway}
                    label="type"
                    setStatus={(e) => {
                      setSelectedGateway(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "SMS", value: "SMS" },
                      { label: "Whatsapp", value: "WHATSAPP" },
                      { label: "EMAIL", value: "EMAIL" },
                      {
                        label: "Push Notification",
                        value: "PUSH NOTIFICATION",
                      },
                    ]}
                  />
                  <StatusFilter
                    label="company"
                    status={company}
                    setStatus={(e) => {
                      setCompany(e);
                      setPage(1);
                    }}
                    options={companyList}
                  />
                  <FilterQuery
                    query={query}
                    label="in Company and Title"
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-1 ml-2">
                {selectedRows.length ? (
                  <div className="d-flex">
                    <StatusFilter
                      status={newSelectedStatus}
                      noLabel={true}
                      noMargin
                      setStatus={(e) => {
                        setNewSelectedStatus(e);
                      }}
                      options={[
                        { label: "Select", value: "" },
                        { label: "Schedule", value: "Schedule" },
                        { label: "Cancel", value: "Cancel" },
                        { label: "Fail", value: "Fail" },
                        { label: "Sent", value: "Sent" },
                      ]}
                    />
                    <Button
                      disabled={Boolean(newSelectedStatus === "")}
                      className="btn btn-primary ml-3"
                      onClick={() => setUpdateModal(true)}
                    >
                      Update Status
                    </Button>
                  </div>
                ) : (
                  <div />
                )}

                <Link className="btn btn-primary" to="/message/add">
                  New Reminder
                </Link>
              </div>

              <Table
                keyField="_id"
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={grpColumns}
                remote
                expandRow={expandRow}
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default Message;
