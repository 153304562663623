import api from "../common/api";

export const doUnsubscribeEDM = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post("/unsubscribe", payload)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
