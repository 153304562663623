import api from "../common/api";

export const getGateways = (
  page: number,
  limit: number,
  query: any,
  type: any,
  sort: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`gateway?page=${page}&limit=${limit}${q}${type}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCompanies = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`gateway/company/list`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getMultipleCompanies = (ids: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`gateway/getCompanies`, { ids: ids || [] })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneGatway = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`gateway/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addNewGateway = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`gateway/create`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateGatway = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`gateway/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
