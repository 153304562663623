import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { CheckLoginStatus } from "../service/auth";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const isAlreadyLogin = () => {
    CheckLoginStatus()
      .then((res) => {
        if (res.data.role) {
          setIsLogin(true);
        }
        setIsMounted(true);
      })
      .catch((err) => {
        setIsLogin(false);
        console.log(err);
        setIsMounted(true);
      });
  };

  useEffect(() => {
    isAlreadyLogin();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isMounted && (
        <Route
          {...rest}
          render={(props) =>
            isLogin ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
