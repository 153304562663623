import api from "../common/api";

export const saveRedirection = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return api
            .post("message/statistics/update", payload)
            .then((res: any) => {
                resolve(res);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};